import React, { useCallback, useState } from "react";
import styled from 'styled-components';


const NoID = ({ isInvalid }) => {

  const [inputLink, setInputLink] = useState(null);
  const changeDestination = useCallback((e) => {
    setInputLink(e.target.value);
  });

  const invalidID = 'Virheellinen Etuovi ID';
  const noID = 'Etuovi ID:tä ei ole syötetty';

  const heading = isInvalid ? invalidID : noID;

  return (
    <Container>
      <h1>{heading}</h1>
      <div>
        <input placeholder="Syötä kohteen Etuovi ID" type="number" onChange={changeDestination} />
        <a href={`/${inputLink}`}>Aloita</a>
      </div>
      <p>tai</p>
      <a className="home" href="https://inlkv.fi">Palaa takaisin pääsivulle</a>
    </Container>

  )
}

const Container = styled.div`
  width:100vw;
  max-width:100%;
  height:100vh;
  max-height:100%;
  display:grid;
  place-items: center;
  place-content: center;

  p {
    margin: 40px auto 20px auto;
  }
  h1 {
    font-size: 4rem;
    font-family: 'BentonModern';
    margin-bottom: 4rem;
  }

  a{
    text-decoration:none;
    color:black;
    outline:none;
    border: none;
    background: #c1b2a2;
    padding:20px;
    width:100%;
    text-align: center;
    max-width: 400px;
    color:black;
    font-weight:bold;
    color:white;
    max-width: 200px;
    
    &.home {
      color:black;
      background:none;
      text-decoration:underline;
    }
  }

  div {
    display:grid;
    grid-template-columns: auto auto;
    place-items: center;
    place-content: center;
    grid-gap: 20px 20px;

    * {
      width: 400px;
      text-align: center;
    }
  }

  input {
    background:none;
    outline:none;
    border:none;
    padding:20px;
    color: black;
    border-bottom: 1px solid black;
    max-width:200px;
    
    &::placeholder {
      color: black;
    }
    
  }

  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;

export default NoID;