import React from 'react';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

import BentonModDisp from './BentonModDisp-Regular.js';
import BentonSansBold from './BentonSans-normal.js';
import BentonSansBook from './BentonSans-Book.js';
import logo from './logo.png';


const pdfDocument = (propertyInfo, input, hudElements, closeHudElements, setGeneratingPDF) => {

    // Info
        const postarea = propertyInfo?.property_postarea;
        const quarteroftown = propertyInfo?.property_quarteroftown;
        const title = propertyInfo?.property_title;
        const propertyType = propertyInfo?.property_type;


    // Create our number formatter.
    const formatter = new Intl.NumberFormat('fi-FI', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    const price = formatter.format(propertyInfo.property_price);

    // Capitalise property type font. 
    const propertyTypeCapitalise = propertyType?.charAt(0).toUpperCase() + propertyType?.slice(1)


    const propertyInfoText = `${title} / ${propertyTypeCapitalise}`;
    const propertyPriceText = `${price}`;

    const pdfSideDetails = "SUUNTAA ANTAVA - EI MITTAKAAVASSA.";

    
    const propertyDetails = `    Meillä myyntistailaus kuuluu osaksi
    jokaisen kodin markkinointipro-
    sessia ja tarvittaessa kalustamme
    kohteen ilman lisäkuluja. Meillä
    tämä palvelu on kohteissa koko
    myynnin ajan – ei ainoastaan
    valokuvausta varten.

    Autamme kaikessa asumiseen
    liittyvässä, jotta kodinvaihto ja uusi
    unelmakoti olisivat mahdollisim-
    man helppo ja turvallinen ratkaisu.
    Valitse vain parasta. Valitse IN.
    Välityspalkkio 4,96 % velattomasta 
    kauppahinnasta sis. alv 24 %.`;



        html2canvas(input, {
            useCORS: true,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');

            const pdf = new jsPDF({
                orientation: "landscape",
                unit: "px",
                format: "a4",
                lineHeight: 2
            });

            //FONT DECLARATION
            pdf.addFileToVFS('BentonModDisp.ttf', BentonModDisp);
            pdf.addFileToVFS('BentonSansBold.ttf', BentonSansBold);
            pdf.addFileToVFS('BentonSansBook.ttf', BentonSansBook);
            pdf.addFont('BentonModDisp.ttf', 'BentonModDisp', 'normal');
            pdf.addFont('BentonSansBold.ttf', 'BentonSansBold', 'normal');
            pdf.addFont('BentonSansBook.ttf', 'BentonSansBook', 'normal');

            // PAGE LAYOUT
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            const textWidth = pdf.getStringUnitWidth(propertyInfoText) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
            const textOffset = (pdf.internal.pageSize.height - textWidth) / 2;

            const displayedWidth = 380;
            const displayedHeight = 270;

            const marginX = (pageWidth - displayedWidth) / 2.5;
            const marginY = (pageHeight - displayedHeight) / 2;

            // FLOORPLAN IMAGE
            pdf.addImage(imgData, 'PNG', 85, marginY, displayedWidth, displayedHeight);

            pdf.setFont('BentonSansBook');
            pdf.setFontSize(6);
            pdf.text( pdfSideDetails, 75, marginY + displayedHeight, null, 90)

            // PDF HEADER SECTION
            pdf.setFont('BentonModDisp');
            pdf.setFontSize(28);

            pdf.text(propertyInfoText, 80, 50, null);

            pdf.setFont('BentonSansBook');
            pdf.setFontSize(14);
            pdf.text('VHP.', 80, 70, null);

            pdf.setCharSpace(.4)
            pdf.setFont('BentonSansBold');
            pdf.setFontSize(14);
            pdf.text(propertyPriceText, 110, 70, null);


            let img = new Image();
            img.src = logo;
            pdf.addImage(logo, 'png', 15, 30, 50, 50)

            // PDF FOOTER SECTION
            const gwName = "IN LKV Oy "
            const gwAddress = " |  Korkeavuorenkatu 2 C 48, 20100 Turku  |  P. 050 2512  |  in@inlkv.fi  |  www.inlkv.fi";
            
            pdf.setCharSpace(.4)
            pdf.setFont('BentonSansBold');
            pdf.setFontSize(10);
            pdf.text(gwName, 80, pageHeight - (pageHeight * .1), null);

            pdf.setFont('BentonSansBook', 'normal');
            pdf.text(gwAddress, 120, pageHeight - (pageHeight * .1), null);

            // RIGHT SECTION PARAGRAPH & BACKGROUND BOX
            let positionCalc = marginY + (displayedHeight * 0.10);
            let positionCalc2 = marginY + (displayedHeight * 0.20);
            
            // BACKGROUNG BOX
            pdf.setFillColor(241, 240, 239);
            pdf.rect(marginX + displayedWidth, positionCalc, 135, 220, "F");

            // PARAGRAPH
            pdf.setCharSpace(.3)
            pdf.setFontSize(8);
            pdf.text(propertyDetails, marginX + displayedWidth + 7, positionCalc2, null)


            pdf.save("Pohjakuva_INLKV.pdf");

            for (var i = 0; i < hudElements.length; i++) {
                hudElements[i].removeAttribute("style");
            }
            for (var i = 0; i < closeHudElements.length; i++) {
                closeHudElements[i].removeAttribute("style");
            }

            document.body.removeAttribute("style");
            setGeneratingPDF(false);

        });
};

    export default pdfDocument;