import React, { useCallback, useState } from "react";
import { FloorPlan } from "../FloorPlan/FloorPlan";
import { FurnitureMenu } from "../FurnitureMenu/FurnitureMenu"
import { useStoreState, useStoreActions } from 'easy-peasy';
import styled from 'styled-components';

import undoArrow from './undo.png';

import Dropdown from "./Dropdown/Dropdown";
import ClearFloorPlan from "./ClearFloorPlan/ClearFloorPlan";
import DownloadButton from "./DownloadButton/DownloadButton";
import ActionLabel from './ActionLabel';
import "./UI.styles.scss";



export const UI = ({ appVisibility, propertyInfo }) => {

    const selectShape = useStoreActions(actions => actions.canvas.selectShape);
    const floorPlans = useStoreState(state => state.floorPlans.floorPlan_items)
    const updateFloorplan = useStoreActions(actions => actions.floorPlans.updateFloorplan)  // Move to dropdown
    const undo = useStoreActions((actions) => actions.undoUndo);


    const handleChange = useCallback((e) => {
        e.preventDefault();
        const data = e.target.attributes['data-type'].value;
        selectShape(null)
        updateFloorplan(data)
    });

    return (
        <>
            {
                appVisibility ?
                    <main className="main">
                        <div className="floor_plan__wrapper">
                            <Actions id="actions">
                                <DownloadButton propertyInfo={propertyInfo} />
                                {/* <Undo iconBg={undoArrow} onClick={undo}>
                                    <ActionLabel text="Peruuta" />
                                </Undo> */}
                                <ClearFloorPlan />
                                <Dropdown triggler={handleChange} floorCount={floorPlans} />
                            </Actions>
                            <FloorPlan />
                            <FurnitureMenu />
                        </div>
                    </main>
                    : <></>
            }

        </>
    );
}

const Actions = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    place-content: center;
    place-items: center;
    grid-gap: 45px 0px;

    button {
        &:hover {
            cursor: pointer;
        }
    }
`;


const Undo = styled.button`
    background-color: transparent;
    background-image: url('${props => props.iconBg}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position:center;

    position: relative;

    outline: none;
    border: none;
    width: 50px;
    height: 65px;
    font-size: 4rem;

    display: flex;
    justify-content: center;
    align-items: flex-end;
`;
