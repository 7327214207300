import React from "react";
import model from './model';
import { StoreProvider, createStore } from 'easy-peasy';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { undoRedo } from 'undo-peasy'
import Validator from "./components/Validator/Validator";
import NoID from './components/NoID/NoID';
import './App.scss';

function App() {

  const store = createStore(model, { middleware: [undoRedo()], });
  localStorage.clear(); // Remove undo history

  return (
    <Router>
      <Switch>
        <Route exact path="/"
          render={
            (props) => (
              <NoID {...props} isInvalid={false} />
            )
          }
        />
        <Route exact path="/invalid"
          render={
            (props) => (
              <NoID {...props} isInvalid={true} />
            )
          }
        />
        {/** MAIN APP */}
        <Route
          path="/:propertyID"
          children={
            <StoreProvider store={store}>
              <Validator />
            </StoreProvider>
          }
        >
        </Route>
      </Switch>
    </Router>

  );
}


export default App;
