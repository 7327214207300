import React from 'react';
import { Rect } from 'react-konva';


const HudBackground = ({ x, y, categoryActive }) => {

    return (
        <Rect
            width={110}
            height={categoryActive ? 110 : 55}
            fill={'#849884'}
            cornerRadius={30}
            x={x}
            y={y}
        />
    );
}

export default HudBackground;