import React, { useState } from 'react';
import { Rect } from 'react-konva';
import useImage from 'use-image';


const Action = ({ action, icon, xOffset, yOffset, rotated }) => {

    const [image] = useImage(icon);

    const scaleX = (15 / image?.width);
    const scaleY = (15 / image?.height);
    const scale = Math.max(scaleX, scaleY);

    const [opacity, setOpacity] = useState(1);

    return (
        <Rect
            x={0 + xOffset}
            y={0 + yOffset}
            width={55}
            height={55}
            onClick={action}
            fillPatternImage={image}
            fillPatternScaleX={scale ? scale : 0}
            fillPatternScaleY={scale ? scale : 0}
            fillPatternRepeat={'no-repeat'}
            opacity={opacity}

            offsetX={(-55 / 2) + 8 + (rotated ? 3 : 0)}
            offsetY={(-55 / 2) + 8 + (rotated ? 0 : 4)}

            onMouseEnter={e => {
                // style stage container:
                const container = e.target.getStage().container();
                container.style.cursor = "pointer";
                setOpacity(.7);
            }}
            onMouseLeave={e => {
                const container = e.target.getStage().container();
                container.style.cursor = "default";
                setOpacity(1);
            }}
        />
    );
}
export default Action;