import React, { useCallback, useState, useEffect } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import Tour from "reactour";
import Text from "../Tooltip/Text";
import "./FurnitureMenuItem.styles.scss";

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export const FurnitureMenuItem = (data) => {

    // Set React Tour Initial state
    const [tourState, setTourState] = useState({
        isTourOpen: false,
        isShowingMore: false
    });

    const appendToFloorplan = useStoreActions(actions => actions.furnitures.appendFurniture)
    const furnitures = useStoreState(state => state.furnitures.furniture_items)

    const toggleTourIsClicked = useStoreActions(actions => actions.tourState.tourIsClicked)
    const toggleTourIsClosed = useStoreActions(actions => actions.tourState.tourIsClosed)

    const tourIsClicked = useStoreState(state => state.tourState.isClicked)

    const activeFloor = useStoreState(state => state.floorPlans.active_floor)
    const furnitureSrc = data.data.src;
    const furnitureName = data.data.name;
    const furnitureWidth = data.data.width;
    const furnitureHeight = data.data.height;
    const furnitureCategory = data.data.category;

    const closeTour = () => {
        toggleTourIsClosed();
        setTourState({ isTourOpen: false });
    };

    const openTour = () => {
        setTourState({ isTourOpen: true });
    };

    useEffect(() => {
        const reactour_ = document.getElementById('___reactour');
        if (reactour_) {
            reactour_.addEventListener('click', () => {
                closeTour();
            })
        }
    }, [tourState.isTourOpen])

    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target)


    const [draggingContent, setDraggingContent] = useState(null);


    const showDropArea = useStoreActions(actions => actions.furnitures.setDropAreaVisible);
    const hideDropArea = useStoreActions(actions => actions.furnitures.setDropAreaHidden);


    // DRAG
    const drag = (e) => {

        showDropArea();
        const width_ = e.target.getAttribute('furniture-width');
        const height_ = e.target.getAttribute('furniture-height');
        const src_ = e.target.src;

        const data = {
            activeFloor: activeFloor,
            furnitureCategory: furnitureCategory,
            src: src_,
            width: width_,
            height: height_
        }

        setDraggingContent(JSON.stringify(data));
    }


    const endDrag = useCallback((e) => {
        const dropArea = document.getElementById("drop-area").getBoundingClientRect();

        const y_lowest = dropArea.y;
        const y_max = dropArea.y + dropArea.height;
        const x_lowest = dropArea.x;
        const x_max = dropArea.x + dropArea.width;

        if (
            y_lowest < e.clientY && // Y pos is higher than box top
            y_max > e.clientY && // Y pos is lower than box bottom

            x_lowest < e.clientX && // X pos is higher than box left
            x_max > e.clientX // X pos is lower than box right
        ) {
            if (draggingContent !== null) {
                appendToFloorplan(draggingContent)
                if (!tourIsClicked) {
                    toggleTourIsClicked(true);
                    openTour();
                }
                hideDropArea();
            }
        } else {
            hideDropArea();
        }

    });

    const appendByClick = useCallback((e) => {
        const width_ = e.target.getAttribute('furniture-width');
        const height_ = e.target.getAttribute('furniture-height');
        const src_ = e.target.src;

        const data = {
            activeFloor: activeFloor,
            furnitureCategory: furnitureCategory,
            src: src_,
            width: width_,
            height: height_
        }
        appendToFloorplan(JSON.stringify(data))

        if (!tourIsClicked) {
            toggleTourIsClicked(true);
            openTour();
        }
    })

    const hasShadow = furnitureName.toLowerCase().includes('valkoinen') && (furnitureCategory.toLowerCase() === 'elementit');


    return (
        <div className="furniture-menu-item">
            <div className="furniture-menu-item-wrap">
                <img
                    className={hasShadow?'shadow':'normal'}
                    onClick={appendByClick}
                    height="70"
                    draggable={true}
                    onDragStart={drag}
                    onDragEnd={endDrag}
                    src={furnitureSrc}
                    alt={furnitureName}
                    furniture-width={furnitureWidth}
                    furniture-height={furnitureHeight}
                />
            </div>
            <p>{furnitureName}</p>
            <Tour
                steps={tourConfig}
                rounded={5}
                className="floorplanner"
                isOpen={tourState.isTourOpen}
                onRequestClose={closeTour}
                disableInteraction={true}
                showNavigation={false}
                showNumber={false}
                showButtons={false}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
                closeWithMask={false}
                highlightedMaskClassName={'close_tour'}
            />
        </div>
    );
}

const tourConfig = [
    {
        selector: '#indicator',
        content: () => (
            <div>
                <Text>Klikkaa huonekalua avataksesi valikon.<br />Vedä huonekalua siirtääksesi sitä pohjakuvassa.<br />Käytä valikossa olevaa palloa pyörittääksesi kalustetta</Text>
            </div>
        ),
    }
]
