import React, { useCallback } from "react";
import { useStoreActions } from 'easy-peasy'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import styled from 'styled-components';

import emptyButton from './empty_button.png';
import trash from './trash.png';
import logo from './logo.png';

import ActionLabel from '../ActionLabel';


const ClearFloorPlan = () => {


  // Clear floorplan
  const clearFloor = useStoreActions(actions => actions.furnitures.removeAllFurnitures)
  const confirmClearFloor = useCallback((e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui confirm'>
            <Content>

              <div className="info">
                <TopRow>
                  <h1>Huomio!</h1>
                  <img width="90" className="trash" src={trash} alt="" />
                  <img height="100" className="logo" src={logo} alt="" />
                </TopRow>
                <p>Valitsemalla "Tyhjennä" menetät kaikki tallentamattomat muutokset.<br />Tyhjennystä ei voi perua.</p>
              </div>
              <div className="actions">
                <button onClick={onClose}>Peruuta</button>
                <button
                  onClick={() => {
                    clearFloor();
                    onClose();
                  }}
                >
                  Tyhjennä
                    </button>
              </div>
            </Content>
          </div>
        );
      }
    });
  })

  return (
    <Empty iconBg={emptyButton} className="clearFloor" onClick={confirmClearFloor} >
      <ActionLabel text="Tyhjennä" />
    </Empty>
  );
}

const TopRow = styled.section`
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-content: center;
  place-items: center;

  h1 {
    text-align: left;
    font: normal normal 600 60px/65px 'BentonMod';
    place-self: self-start;
    margin: 0;
  }

  img.trash {
    margin: 55px auto 0 auto;
  }
  img.logo {
    place-self: self-start;
    justify-self: self-end;
  }
`;

const Content = styled.div`

  width:900px;
  height: 440px;
  color:black;

  p {
    text-align: center;
    font: normal normal normal 20px/30px 'BentonSans';
    letter-spacing: 0px;
    color: #000000;
    margin: 35px auto;
  }

  button {
    width: 150px;
    height: 65px;
    outline:none;
    border:none;
    margin: 0 25px;
    font: normal normal bold 20px/23px BentonSans;
    color: white;

    &:first-of-type {
      background: #C1B2A2 0% 0% no-repeat padding-box;
    }
    background: #849884 0% 0% no-repeat padding-box;

    &:hover {
      cursor:pointer;
    }
  }


`;


const Empty = styled.button`
  background-color: transparent;
  background-image: url('${props => props.iconBg}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position:center;

  position: relative;

  outline: none;
  border: none;
  width: 50px;
  height: 75px;
  font-size: 4rem;

  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export default ClearFloorPlan;

