import React, { useEffect, useState } from 'react'
import { useStoreState } from 'easy-peasy';
import { Header } from "../Header/Header.component";
import { SmallResolutionNoRender } from '../NoRender/SmallResolutionNoRender';
import { UI } from "../UI/UI";
import { useParams, Redirect } from "react-router-dom";
import GenerationScreen from '../UI/GenerationScreen';

/**
 * 
 * Thic component checks if the response is valid from WordPress.
 * If WordPress returns false for request, an error will be shown.
 * 
 */

const Validator = () => {

    const PROPERTY_API_URL = useStoreState(state => state.api.property_info_url);
    const [propertyInfo, setPropertyInfo] = useState([]);

    let { propertyID } = useParams();
    useEffect(async () => {
        const response = await fetch(`${PROPERTY_API_URL + propertyID}`);
        const data = await response.json();
        setPropertyInfo(data)
    }, []);


    const [appVisibility, setAppVisibility] = useState(false);

    useEffect(() => {
        if (propertyInfo !== false && !Array.isArray(propertyInfo)) {
            setAppVisibility(true);
        }
    }, [propertyInfo])


    // Is generating pdf
    const generating = useStoreState(state => state.pdf.isGenerating);

    return (
        <>
            {propertyInfo ?
                <>
                    <Header propertyInfo={propertyInfo} appVisibility={appVisibility} />
                    <UI propertyInfo={propertyInfo} appVisibility={appVisibility} />
                    <SmallResolutionNoRender />
                    {generating ? <GenerationScreen /> : <></>}
                </>
                :
                <Redirect to={"/invalid"} />
            }
        </>
    )
}

export default Validator;