import React, { useCallback } from 'react'
import styled from 'styled-components';
import ScaleImg from './scale.png';
import { useStoreState } from 'easy-peasy';

const ScaleIndicator = () => {

    const shrinkingPercentage = useStoreState(state => state.settings.scale.shrinkingPercentage);

    const translateDimensions = useCallback(() => {

        // Original values
        let width = 552;
        let height = 100;
        let percentage = shrinkingPercentage;

        // Values to subtract
        let width_percentage = width * percentage;
        let height_percentage = height * percentage;

        let newWidth = width - width_percentage;
        let newHeight = height - height_percentage;

        let return_ = [newWidth, newHeight];

        return return_;
    });

    const defaultIndicatorSize = translateDimensions();


    return (
        <Scale>
            <span></span>
            <Center
                scaleWidth={defaultIndicatorSize[0]}
                scaleHeight={defaultIndicatorSize[1]}
            >
                <img src={ScaleImg} />
                <p>Huom.! Tässä esitetyt pohjakuvat eivät ole tarkassa mittakaavassa. Mitat ovat suuntaa-antavia.<br/>Seiniin tehtävät aukot ja seinien poistot tarkistettava rakennesuunnittelijalla.</p>
            </Center>
            <span></span>
        </Scale>
    )
}

const Scale = styled.div`
    height:50px;
    position: absolute;
    bottom: -50px;
    display:grid;
    grid-template-columns: 205px 1000px 495px;
`;
const Center = styled.section`
    display:grid;
    place-items:center;
    place-content: center;
    grid-template-columns: auto auto;
    p {
        font: normal normal normal 12px/20px 'BentonSans';
        letter-spacing: 0px;
        color: #5F5F62;
        max-width: 601px;
        margin-left:60px;
    }

    img {
        width: ${props => props.scaleWidth}px;
        height: ${props => props.scaleHeight}px;
    }
`;

export default ScaleIndicator;