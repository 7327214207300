import React from 'react'

import "./Button.styles.scss";
import dropdownImg from '../../assets/images/Polygon.png';

const Button = ({
    variant,
    text,
    onClick,
    dropdown,
    href
    }) => {

    return (
      <a
        className={`btn btn-${variant}`}
        onClick={onClick}
        href={`${href}`}
      >
        <span>{text}</span>
        {dropdown ? <img className="" src={dropdownImg} alt="dropdown image" /> : null}
      </a>
    )
  }
  
export default Button;