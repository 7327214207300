import React, { useState, useEffect, useCallback } from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import ScaleIndicator from './ScaleIndicator';

import RenderFurnitures from './RenderFurnitures.js';


import "./FloorPlan.styles.scss";



export const FloorPlan = () => {

    const floorplanWidth = useStoreState(state => state.settings.scale.containerWidth);
    const floorplanHeight = useStoreState(state => state.settings.scale.containerHeight);

    // Floorplan fetch start
    let { propertyID } = useParams();

    const fetchFloorPlans = useStoreActions(actions => actions.floorPlans.fetchFloorPlans)
    const floorPlans = useStoreState(state => state.floorPlans.floorPlan_items)
    const activeFloorplanSRC = useStoreState(state => state.floorPlans.active_floor)

    const setShrinkingPercentage = useStoreActions(actions => actions.settings.updateShrinkingPercentage)

    const [floorplanSRC, setFloorplanSRC] = useState(activeFloorplanSRC);

    const FLOORPLANS_API_URL = useStoreState(state => state.api.floorplans_url);

    const [orientation, setOrientation] = useState('100% auto');

    // Only on initial load, no dependencies
    useEffect(async () => {
        const response = await fetch(`${FLOORPLANS_API_URL + propertyID}`);
        const data = await response.json();
        fetchFloorPlans(data)
    }, []);

    // Pass floorPlans as dependency, when changed this is updated
    useEffect(async () => {
        if (floorPlans[activeFloorplanSRC]) {
            setFloorplanSRC(floorPlans[activeFloorplanSRC].src)
            // let originalHeight = floorPlans[activeFloorplanSRC].originalHeight;

            // // Get difference
            // let difference = originalHeight - floorplanHeight;

            // // How many percent if difference of originalHeight?
            // // Formula Y/X = P%
            // // Example question: What percent of 1600 is 500
            // // 500/1600 = 0.3333... 
            // let shrinkingPercentage = difference / originalHeight;

            const original_width = floorPlans[activeFloorplanSRC].originalWidth;
            const original_height = floorPlans[activeFloorplanSRC].originalHeight;
            const difference_width = original_width - floorplanWidth;
            const difference_height = original_height - floorplanHeight;
            const shrinkingPercentage_width = difference_width / original_width;
            const shrinkingPercentage_height = difference_height / original_height;

            const isWider = original_width > original_height;
            setShrinkingPercentage(isWider ? shrinkingPercentage_width : shrinkingPercentage_height);

            let diff = original_width - original_height;
            let orientation_;
            if (diff < 0) {
                orientation_ = 'auto 100%';
            } else {
                if ((original_height < 1000) || (original_width - original_height) > 2500) {
                    orientation_ = '100% auto';
                } else {
                    orientation_ = 'auto 100%';
                }
            }

            setOrientation(orientation_);

        }
    }, [floorPlans, activeFloorplanSRC])

    const [floorplanHeightInScale] = useState(floorplanHeight);

    // Furnitures
    const furnitures = useStoreState(state => state.furnitures.furniture_items)
    const tourHasBeenClosed = useStoreState(state => state.tourState.isClosed)
    const dropAreaVisibility = useStoreState(state => state.furnitures.drop_area_visible);

    return (
        <>
            <Container
                id="floor_plan"
                className={`floor_plan active-${activeFloorplanSRC}`}
                containerWidth={floorplanWidth}
                containerHeight={floorplanHeight}
                backgroundImage={floorplanSRC}
                imageHeight={floorplanHeightInScale}
                orientation={orientation}
            >
                <RenderFurnitures furnitures={furnitures} />
                {!tourHasBeenClosed ? <FurnitureIndicator id="indicator" /> : <></>}
                {dropAreaVisibility ? <DropArea id="drop-area" /> : <></>}
            </Container>
            <ScaleIndicator />

        </>
    );
}

const DropArea = styled.section`
    position:absolute;
    top:0%;
    left:0%;
    width:100%;
    height: 100%;
    filter: blur(5px);
`;


const FurnitureIndicator = styled.div`
    position:absolute;
    top:50% !important;
    left:50% !important;
    transform:translate(-50%,-50%);
    height:180px;
    width:180px;
`;

const Container = styled.div`
    min-width: ${props => props.containerWidth}px;
    max-width: ${props => props.containerWidth}px;
    
    height: ${props => props.containerHeight}px;
    background-image: url('${props => props.backgroundImage}');
    // background-size: auto ${props => props.imageHeight}px;
    background-size: ${props => props.orientation};
    box-sizing: content-box;
    box-shadow: unset !important;
    
    -webkit-transition: background-image .2s ease-in-out;
    transition: background-image .2s ease-in-out;
    
    div {
        box-shadow: unset !important;
        top: -150px;

    }

    z-index:1;
`;
