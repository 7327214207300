import React, { useState, useEffect } from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { VscChromeMinimize, VscArrowLeft } from 'react-icons/vsc';
import "./FurnitureMenu.styles.scss";
import { FurnitureMenuItem } from '../FurnitureMenuItem/FurnitureMenuItem';
import Tour from "reactour";
import { useCallback } from "react/cjs/react.development";
import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'



export const FurnitureMenu = () => {

    const furnitureMenu = useStoreState(state => state.furniture_menu.furniture_menu_state)
    const FURNITURES_API_URL = useStoreState(state => state.api.furnitures_url);

    const [currentPage, setCurrentPage] = useState(furnitureMenu.active_page);
    const [categoriesVisible, setCategoriesVisible] = useState(true);

    const toggleTourIsClicked = useStoreActions(actions => actions.tourState.tourIsClicked)
    const tourIsClicked = useStoreState(state => state.tourState.isClicked)

    const updateCurrentPage = (key) => {
        if (!tourIsClicked) {
            toggleTourIsClicked(true);
            openTour();
        }

        if (key === 'front_page') {

            // Set menu visible
            setMenuVisible(true);

            setCategoriesVisible(true);
            setCurrentPage(key);

        } else {
            setCategoriesVisible(false);
            setCurrentPage(key);
        }
    }


    // Fetch furniture data

    const fetchFurnitures = useStoreActions(actions => actions.furniture_menu.fetchFurnitures)

    useEffect(async () => {
        const response = await fetch(FURNITURES_API_URL);
        const data = await response.json();
        fetchFurnitures(data)
    }, []);


    const [menuVisible, setMenuVisible] = useState(true);


    // Set Count for React Tour
    const [count, setCount] = useState(0);

    // Set React Tour Initial state
    const [tourState, setTourState] = useState({
        isTourOpen: false,
    });


    const closeTour = () => {
        setTourState({ isTourOpen: false });
        toggleTourIsClicked(false);
    };

    const openTour = () => {
        // Set count to limit the tour pop on to one on the furniture menu.
        setCount(count + 1)
        if (count <= 0) {
            setTourState({ isTourOpen: true });
        }
        else { toggleTourIsClicked(false); }
    };

    useEffect(() => {
        const reactour_ = document.getElementById('___reactour');
        if (reactour_) {
            reactour_.addEventListener('click', () => {
                closeTour();
            })
        }
    }, [tourState.isTourOpen])

    const disableBody = target => disableBodyScroll(target)
    const enableBody = target => enableBodyScroll(target)

    const [menuZIndex, setMenuZIndex] = useState(1);

    return (
        <Container menuZIndex={menuZIndex}>

            <FurnitureMenuWrap>
                <div className="furniture-menu__wrapper">
                    <MenuToggleArrow onClick={
                        () => {
                            setMenuVisible(true);
                            setMenuZIndex(1);
                        }
                    } />
                    <Menu menuVisible={menuVisible}>
                        <Actions>
                            {
                                (currentPage !== 'front_page') ?
                                    <Instructions onClick={() => updateCurrentPage('front_page')}>
                                        <VscArrowLeft />
                                    </Instructions>
                                    : <></>
                            }
                            <CloseHud className="hud_close" onClick={() => {
                                setMenuVisible(false);
                                setMenuZIndex(0);
                            }
                            }>
                                <VscChromeMinimize />
                            </CloseHud>
                        </Actions>
                        <ActivePage>{furnitureMenu.categories[currentPage].name}</ActivePage>
                        <div className="furniture-menu">
                            <div className={`furniture-menu__categories ${categoriesVisible ? 'categories__big' : 'categories__small'}`}>

                                <ScrollContainer vertical={true}>
                                    {
                                        Object.keys(furnitureMenu.categories).map((key, index) => {
                                            if (key !== 'front_page' && furnitureMenu.categories[key].furnitures.length > 0) {
                                                return (
                                                    <div key={`${key}_${index}`} className="category__wrapper" onClick={() => updateCurrentPage(key)}>
                                                        <img src={furnitureMenu.categories[key].cover} alt={furnitureMenu.categories[key].name} />
                                                        <p className={`categories ${(furnitureMenu.categories[key].name === furnitureMenu.categories[currentPage].name) ? 'active' : ''}`}>{furnitureMenu.categories[key].name}</p>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </ScrollContainer>
                            </div>

                            <div className="furniture-menu__furnitures">
                                {
                                    // Rendering furnitures
                                    !categoriesVisible ?
                                        furnitureMenu.categories[currentPage].furnitures.map((item, index) => {
                                            return (
                                                <FurnitureMenuItem
                                                    key={`${item.name}_${index}`}
                                                    data={item}
                                                />
                                            )
                                        }) : ''
                                }
                                <Tour
                                    steps={tourConfig}
                                    rounded={5}
                                    isOpen={tourState.isTourOpen}
                                    onRequestClose={closeTour}
                                    className="floorplanner"
                                    disableInteraction={true}
                                    showNavigation={false}
                                    showNumber={false}
                                    showButtons={false}
                                    onAfterOpen={disableBody}
                                    onBeforeClose={enableBody}
                                    closeWithMask={false}
                                />
                            </div>

                            <div className={`instructions${categoriesVisible ? ' visible' : ''}`}>
                                <p>Aloita valitsemalla kalusteryhmä klikkaamalla kuvaa. Voit tallentaa suunnitelmasi kuvana painamalla <b>Tallenna</b>-kuvaketta.</p>
                            </div>
                        </div>
                    </Menu>
                </div>
            </FurnitureMenuWrap>
        </Container>
    );
}

const Actions = styled.div`
display: block;
width: 100%;
height: 50px;
position: absolute;
top: 0;
display: flex;
flex-direction: row;
padding-top:5px;
padding-left:10px;
`;


const Container = styled.div`
    width: 100%;
    height: 755px;
    margin-top:45px;
    padding-left:15px;
    overflow-x:hidden;
    
    
    @media only screen and (max-width: 1750px) {
        width: 480px;
        // height: 100%;
        height: 800px;
        padding-left: 15px;
        overflow-x: hidden;
        position: absolute;
        right: 20px;
        margin-top:0px;
        top: 0;
    }

    z-index: ${props => props.menuZIndex};

`;

const Instructions = styled.button`
    outline: none;
    border: none;
    background: none;
    font-weight: 500;
    color: #505053;
    &:hover {
        cursor: pointer;
    }
`;

const CloseHud = styled.button`
    width: 50px;
    height: 50px;
    outline: none;
    border: none;
    background: none;
    font-size: 1.3rem;

    &:hover {
        cursor: pointer;
    }
`;

const ActivePage = styled.h3`
    text-align: center;
    font-family: "BentonModern";
    font-size: 3.75rem;
    margin-top: 50px;
    font-weight: 400;
    color: #343434;
`;

const Menu = styled.div`
    background: white;
    position: absolute;
    
    right: ${props => props.menuVisible ? '0%' : 'calc(-100% - 24px)'};
    @media only screen and (min-width: 1750px) {
        right: ${props => props.menuVisible ? '0%' : 'calc(-100% - 12px)'};
    }
    height: 100%;
    width:100%;
    overflow:hidden;
    transition: right .3s ease;

    box-shadow: 6px 6px 6px rgba(0,0,0,.15);
    box-sizing: border-box;
`;

const MenuToggleArrow = styled.div`

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 45px 25px 0;
    border-color: transparent #C1B3A2 transparent;
    
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    &:hover {
        cursor:pointer;
    }
`;

const FurnitureMenuWrap = styled.section`
    position:relative;
    height: 100%;
    max-height: 700px;
    width: 100%;
    place-content: center;
    place-items: center;
    display: grid;
    border-right: 15px solid transparent;
    
    top: 15px;

    @media only screen and (min-width: 1750px) {
        top: 47%;
        transform: translateY(-50%);
    }
}
`;

const Text = styled.p`
  font-family: "BentonSans";
  margin: 0;
  line-height: 1.5;
  font-size: ${props => (props.size ? props.size : "inherit")};
`;



const tourConfig = [
    {
        selector: '.furniture-menu-item',
        content: () => (
            <div>
                <Text>
                    Klikkaa huonekalua sijoittaaksesi sen pohjakuvaan.
        </Text>
            </div>
        ),
    }]
