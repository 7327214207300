import React, { useCallback, useState } from 'react';
import Furniture from './Furniture';
import { Stage, Layer, Rect, Group, Transformer } from 'react-konva';
import { useStoreActions, useStoreState } from 'easy-peasy';
import FurnitureHud from './FurnitureHud/FurnitureHud';
import _ from 'lodash';


const RenderFurnitures = ({ furnitures }) => {

    // const [selectedId, selectShape] = useState(null);
    const selectedId = useStoreState(state => state.canvas.selectedId);
    const selectShape = useStoreActions(actions => actions.canvas.selectShape);
    const tourIsClicked = useStoreState(state => state.tourState.isClicked)


    /**
     * Handle toggle of a single furniture transformer (rotate)
     */
    const checkDeselect = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            if (selectedId) {
                resetFurnitureCategory(selectedId);
            }
            selectShape(null);
        }
    };

    /**
     * Change dimensions of furniture image by using shrinkingPercentage, in order
     * for the furnitures to be in scale.
     * 
     * shrinkingPercentage is calculated by checking how much a floorplan has been shrinked
     * in order to fit the container/canvas
     * 
     */
    const shrinkingPercentage = useStoreState(state => state.settings.scale.shrinkingPercentage);

    const translatedDimensions = useCallback((furnitureWidth, furnitureHeight) => {
        let width = furnitureWidth;
        let height = furnitureHeight;
        let percentage = shrinkingPercentage;

        // Values to subtract
        let width_percentage = width * percentage;
        let height_percentage = height * percentage;

        let newWidth = width - width_percentage;
        let newHeight = height - height_percentage;

        return [newWidth, newHeight];
    });

    const removeFromFloorplan = useStoreActions(actions => actions.furnitures.removeFurniture);
    const flipFurniture = useStoreActions(actions => actions.furnitures.flipFurniture);
    const flopFurniture = useStoreActions(actions => actions.furnitures.flopFurniture);

    const deleteFurniture = useCallback((id) => {
        selectShape(null);
        removeFromFloorplan(id);
    })




    // Get currently active floor, used to determine if a furniture should be rendered or not.
    const activeFloor = useStoreState(state => state.floorPlans.active_floor)



    /**
     * furnitures is an array with all added furnitures.
     * Create a grouped object with category as key
     */
    const grouped_furnitures = _.groupBy(furnitures, furniture => furniture.category);

    /**
     * Order specified by customer:
     * 
     *  - Lattiamateriaalit
     *  - Matot
     *  - Elementit
     *  - Tuolit
     *  - Muut (all other)
     *  - Sisustus
     * 
     */
    const order = [
        'Lattiamateriaalit',
        'Matot',
        'Elementit',
        'Tuolit',
        'Sohvat',
        'Sängyt',
        'Pöydät',
        'Keittiö',
        'Kylpyhuone',
        'Sisustus',
        'active_furniture'
    ];

    // Reorder grouped furnitures according to customer order.
    const furnitureItems = {};
    order.forEach(category => {
        if (grouped_furnitures[category]) {
            furnitureItems[category] = grouped_furnitures[category];
        }
    });



    // PDF
    const generating = useStoreState(state => state.pdf.isGenerating);

    const [hudPosition, setHudPosition] = useState({ x: 0, y: 0 });

    const updateFurniturePosition = useStoreActions(actions => actions.furnitures.updateFurniturePosition);
    const updateFurnitureResize = useStoreActions(actions => actions.furnitures.updateFurnitureResize);
    const updateFurnitureFillScale = useStoreActions(actions => actions.furnitures.updateFurnitureFillScale);
    const updateFurnitureRotation = useStoreActions(actions => actions.furnitures.updateFurnitureRotation);

    const updateFurnitureCategory = useStoreActions(actions => actions.furnitures.updateFurnitureCategory);
    const resetFurnitureCategory = useStoreActions(actions => actions.furnitures.resetFurnitureCategory);

    const [categoryActive, setActiveCategory] = useState(null);

    // console.log(furnitureItems);

    return (
        <Stage
            width={1000}
            height={880}
            onMouseDown={checkDeselect}
            onTouchStart={checkDeselect}
        >

            {
                // Create layer for each category in grouped object
                Object.keys(furnitureItems).map(key => {
                    return (
                        <Layer key={key} pixelRatio={1}>
                            {furnitureItems[key].length > 0 && furnitureItems[key].map(furniture => {
                                // Only show furniture if it has been added on the currently active floor
                                if (activeFloor === furniture.activeFloor) {
                                    return (
                                        <Furniture
                                            key={furniture.id}

                                            resizeable={(furniture.originalCategory === 'Lattiamateriaalit' || furniture.originalCategory === 'Elementit') ? true : false}
                                            tourIsClicked={tourIsClicked}
                                            FurnitureScaleY={furniture.scaleY}
                                            FurnitureScaleX={furniture.scaleX}
                                            position={{ x: furniture.x, y: furniture.y }}
                                            setHudPosition={setHudPosition}
                                            generating={generating}
                                            uniqueid={furniture.id}
                                            originalCategory={furniture.originalCategory}
                                            category={furniture.category}
                                            bg={furniture.src}
                                            stageDimensions={[window.innerWidth, window.innerHeight]}
                                            dimensions={translatedDimensions(furniture.width, furniture.height)}
                                            furnitureWidth={furniture.width}
                                            furnitureHeight={furniture.height}
                                            isSelected={furniture.id === selectedId}
                                            selectShape={selectShape}
                                            hudOpen={(selectedId !== null)}
                                            resetFurnitureCategory={resetFurnitureCategory}
                                            onSelect={(e) => {
                                                selectedId !== null && resetFurnitureCategory(selectedId);
                                                selectShape(furniture.id);
                                                if(e.target.getParent()) {
                                                    e.target.moveToTop(); // Move to top in layer
                                                }

                                                setHudPosition(e.target.getAbsolutePosition()); // Set hud position
                                                setActiveCategory(furniture.originalCategory);
                                                updateFurnitureCategory(furniture.id);
                                            }}
                                            updateFurniturePosition={updateFurniturePosition}
                                            updateFurnitureResize={updateFurnitureResize}
                                            updateFurnitureFillScale={updateFurnitureFillScale}
                                            updateFurnitureRotation={updateFurnitureRotation}
                                            fillScale={[furniture.fillScaleX, furniture.fillScaleY]}
                                            rotation={furniture.rotation}
                                            resetFurnitureCategory={resetFurnitureCategory}
                                            updateFurnitureCategory={updateFurnitureCategory}
                                        />
                                    )
                                }
                            })}

                        </Layer>
                    )
                })
            }
            <Layer pixelRatio={1}>
                <FurnitureHud
                    selectShape={selectShape}
                    selectedId={selectedId}
                    x={hudPosition.x - 150}
                    y={hudPosition.y - 150}
                    dimensions={[55, 170]}
                    categoryActive={
                        categoryActive !== 'Lattiamateriaalit' &&
                        categoryActive !== 'Elementit'
                    }
                    removeFromFloorplan={deleteFurniture}
                    toggleFlop={flopFurniture}
                    toggleFlip={flipFurniture}

                />
            </Layer>
        </Stage>
    )
}
export default RenderFurnitures;