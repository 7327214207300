import { action  } from 'easy-peasy';
import { undoable } from 'undo-peasy';

const TESTMODE = false;
const OFFSET = 180;
const MAX_FURNITURES = 100;
const domain = (process.env.NODE_ENV === 'production') ? 'inlkv.fi' : 'lkv.stage.gwdev.fi';

export default undoable({

    pdf: {
        isGenerating: false,
        toggleIsGenerating: action((state, payload) => {
            state.isGenerating = payload;
        })
    },
    canvas: {
        selectedId: null,
        selectShape: action((state, payload) => {
            state.selectedId = payload;
        })
    },
    /**
     * 
     * SETTINGS START
     * 
     */
    settings: {
        scale: {
            shrinkingPercentage: 1,
            containerWidth: 1000,
            containerHeight: 700,

        },
        updateShrinkingPercentage: action((state, payload) => {
            state.scale.shrinkingPercentage = payload;
        })
    },

    /**
    * 
    * SETTINGS END
    * 
    */


    /**
     * FURNITURES START
     */
    furnitures: {
        drop_area_visible: false,
        furniture_items: [],
        // Actions
        setDropAreaVisible: action((state) => {
            state.drop_area_visible = true;
        }),
        setDropAreaHidden: action((state) => {
            state.drop_area_visible = false;
        }),
        appendFurniture: action((state, payload) => {

            if (state.furniture_items.length < MAX_FURNITURES) {

                payload = JSON.parse(payload);
                const activeFloor = payload.activeFloor;
                const furnitureCategory = payload.furnitureCategory;
                const src = payload.src;
                const width_ = payload.width;
                const height_ = payload.height;

                const furnitureImageSrc = src;

                // // Create object with id and image src
                const newPayload = {
                    id: `_${new Date().getTime()}`,
                    src: furnitureImageSrc,
                    width: width_,
                    height: height_,
                    activeFloor: activeFloor,
                    originalCategory: furnitureCategory,
                    category: 'active_furniture',
                    x: 500,
                    y: 350 + OFFSET,
                    scaleY: 1,
                    scaleX: 1,
                    fillScaleX: 0,
                    fillScaleY: 0,
                    rotation: 0
                };

                // Add payload to furniture_items array
                state.furniture_items.push(newPayload);
            } else {
                alert(`Maximum reached: ${MAX_FURNITURES}`);
            }
        }),

        removeFurniture: action((state, payload) => {
            const index = state.furniture_items.findIndex(x => x.id === payload);

            //Remove item at index dataID from furniture_items
            state.furniture_items.splice(index, 1);
        }),

        removeAllFurnitures: action((state) => {
            state.furniture_items = [];
        }),
        updateFurniturePosition: action((state, payload) => {
            const index = state.furniture_items.findIndex(x => x.id === payload.uniqueid);
            state.furniture_items[index].x = payload.position.x;
            state.furniture_items[index].y = payload.position.y;
        }),
        updateFurnitureResize: action((state, payload) => {
            const index = state.furniture_items.findIndex(x => x.id === payload.uniqueid);
            state.furniture_items[index].scaleX = payload.scale[0];
            state.furniture_items[index].scaleY = payload.scale[1];
        }),
        updateFurnitureFillScale: action((state, payload) => {
            const index = state.furniture_items.findIndex(x => x.id === payload.uniqueid);
            state.furniture_items[index].fillScaleX = payload.x;
            state.furniture_items[index].fillScaleY = payload.y;
        }),
        updateFurnitureRotation: action((state, payload) => {
            const index = state.furniture_items.findIndex(x => x.id === payload.uniqueid);
            state.furniture_items[index].rotation = payload.rotation;
        }),
        resetFurnitureCategory: action((state, payload) => {
            const index = state.furniture_items.findIndex(x => x.id === payload);
            state.furniture_items[index].category = state.furniture_items[index].originalCategory;
        }),
        // Activate a furniture transformer by updating it's category
        updateFurnitureCategory: action((state, payload) => {
            const index = state.furniture_items.findIndex(x => x.id === payload);
            state.furniture_items[index].category = "active_furniture";
        }),
        flopFurniture: action((state, payload) => {
            const index = state.furniture_items.findIndex(x => x.id === payload);
            state.furniture_items[index].scaleY = state.furniture_items[index].scaleY * -1;
        }),
        flipFurniture: action((state, payload) => {
            const index = state.furniture_items.findIndex(x => x.id === payload);
            state.furniture_items[index].scaleX = state.furniture_items[index].scaleX * -1;
        }),
    },
    /**
     * FURNITURES END
     */

    /**
     * 
     * API START
     * Url to be changed on publish
     * 
     */
    api: {
        furnitures_url: `https://${domain}/wp-json/gw-floorplanner-helper/get-furnitures`,
        floorplans_url: `https://${domain}/wp-json/gw-floorplanner-helper/floorplans/`,
        property_info_url: `https://${domain}/wp-json/gw-floorplanner-helper/property/`,
    },
    /**
    * 
    * API END
    * 
    */

    /**
    * FURNITURE MENU START
    */
    furniture_menu: {
        furniture_menu_state: {
            active_page: 'front_page',
            categories: {
                front_page: {
                    name: 'Ladataan...',
                }
            }
        },

        // Actions

        fetchFurnitures: action((state, payload) => {

            let categories = {};
            categories.front_page = {
                name: 'Ohjeet',
            };

            Object.keys(payload).map((key, item) => {
                const data = JSON.parse(payload[item]);
                const category_name = Object.keys(data)[0];
                const content = data[category_name];
                categories[category_name] = content;
            });

            state.furniture_menu_state.categories = categories;

        }),
    },
    /**
    * FURNITURE MENU END
    */



    /**
    * FLOORPLAN START
    */
    floorPlans: {

        active_floor: null,

        // Floorplans
        floorPlan_items: [],

        // Actions
        appendfloorPlan: action((state, payload) => {

            const floorPlanImageSrc = payload.target.src;
            const newPayload = {
                id: state.floorPlan_items.length + 1,
                src: floorPlanImageSrc
            };

            state.floorPlan_items.push(newPayload);

        }),
        fetchFloorPlans: action((state, payload) => {

            let status = 200;
            let floorPlan_items = [];

            if (payload.data) {
                status = payload.data.status;
            }

            if (status !== 404) {
                payload.forEach(element => {

                    let data = JSON.parse(element);

                    if (TESTMODE) {
                        data.src = '/example.png'
                        data.originalWidth = 1237;
                        data.originalHeight = 3738;
                        floorPlan_items[data.story] = data;
                    } else {
                        // If has img src
                        if (data.src !== null) {
                            // REAL DATA
                            data.id = data.story;
                            data.originalWidth = data.width;
                            data.originalHeight = data.height;
                            floorPlan_items[data.story] = data;
                            // REAL DATA
                        }
                    }
                });
            }

            state.floorPlan_items = floorPlan_items;
            state.active_floor = Object.keys(floorPlan_items)[0];
        }),
        updateFloorplan: action((state, payload) => {
            state.active_floor = payload;
        })
    },
    /**
    * FLOORPLAN END
    */

    /**
    * TOUR START
    */

    tourState: {
        isClicked: false,
        isClosed: false,
        tourIsClicked: action((state, payload) => {
            state.isClicked = payload;
        }),
        tourIsClosed: action((state) => {
            state.isClosed = true;
        })
    },
    /**
    * TOUR END
    */
},

)