import React, { useCallback } from 'react';
import { Group } from 'react-konva';
import HudBackground from './HudBackground';
import Action from './Action';

import CloseIcon from '../Icons/x.png';
import TrashIcon from '../Icons/trash.png';
import FlopIcon from '../Icons/flop.png';
import FlipIcon from '../Icons/flip.png';





// const FurnitureHud = ({ generating, remove, isSelected, dimensions, setFlip, setFlop, flip, flop }) => {
const FurnitureHud = ({ selectShape, selectedId, x, y, dimensions, removeFromFloorplan, toggleFlip, toggleFlop, categoryActive }) => {

    const remove = useCallback(() => {
        removeFromFloorplan(selectedId);
    });

    const flip = useCallback(() => {
        toggleFlip(selectedId)
    })

    const flop = useCallback(() => {
        toggleFlop(selectedId)
    })

    const close = () => {
        selectShape(null)
    }


    return (
        <Group
            x={x}
            y={y}
            visible={(selectedId !== null)}
        >
            <HudBackground
                x={0}
                y={0}
                generating={false}
                dimensions={dimensions}
                categoryActive={categoryActive}
            />
            <Action action={close} icon={CloseIcon} xOffset={0} yOffset={2} />
            <Action action={remove} icon={TrashIcon} xOffset={55} yOffset={0} />
            {
                categoryActive?<>
                <Action action={flop} icon={FlopIcon} xOffset={0} rotated={true} yOffset={55} />
                <Action action={flip} icon={FlipIcon} xOffset={55} yOffset={55} />
                </>:<></>
            }
        </Group>
    )

}


export default FurnitureHud;
