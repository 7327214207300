import React from 'react'
import styled from 'styled-components';

const ActionLabel = ({text}) => {
    return (
        <Label>{text}</Label>
    )
}

const Label = styled.span`
    text-align: center;
    font: normal normal normal 20px/30px 'BentonSans';
    letter-spacing: 0px;
    color: #505053;
    opacity: 1;
    position:absolute;
    top: 75px;
`;

export default ActionLabel;