import React, { useState, useCallback, useEffect } from 'react'

import pdfGenerator from "../pdfGenerator/pdfGenerator";
import styled from 'styled-components';
import save from './save.png'; // Tell webpack this JS file uses this image
import ActionLabel from '../ActionLabel';

import { useStoreActions, useStoreState } from 'easy-peasy';

const DownloadButton = ({ propertyInfo }) => {

    const setGeneratingPDF = useStoreActions(actions => actions.pdf.toggleIsGenerating);
    const selectedId = useStoreState(state => state.canvas.selectedId);
    const selectShape = useStoreActions(actions => actions.canvas.selectShape);

    const [allowedToPrint, setAllowedToPrint] = useState(false);


    /**
     * Initialize pdf-save
     */
    const startPDFPrint = () => {

        // If no HUD is open, just print the pdf
        if (selectedId === null) {
            printDocument();
        } else {
            // If some hud is open, close it and allow PDF printing after that.
            selectShape(null);
            setAllowedToPrint(true);
        }
    }

    // Listed for changes in when an hud is open.
    // If also allowed to pring (user has clicked on button), download pdf.
    useEffect(() => {
        if (selectedId === null && allowedToPrint) {
            setAllowedToPrint(false);
            printDocument();
        }
    }, [selectedId])


    // DOWNLOADING FLOORPLAN
    const printDocument = useCallback(() => {
        setGeneratingPDF(true);
        // Lock body
        document.body.setAttribute("style", "overflow:hidden;");
        // Scroll to top
        window.scrollTo(0, 0);

        // Downloadable element
        const input = document.getElementById('floor_plan');

        // Elements to hide during download
        const hudElements = document.getElementsByClassName('hud')
        const closeHudElements = document.getElementsByClassName('closeMenu')

        for (var i = 0; i < hudElements.length; i++) {
            hudElements[i].setAttribute("style", "display:none;");
        }
        for (var i = 0; i < closeHudElements.length; i++) {
            closeHudElements[i].setAttribute("style", "display:none;");
        }

        pdfGenerator(propertyInfo, input, hudElements, closeHudElements, setGeneratingPDF)
    })

    return (
        <Download iconBg={save} className="download" onClick={startPDFPrint}>
            <ActionLabel text="Tallenna" />
        </Download>
    )
}



const Download = styled.button`
    background-color: transparent;
    background-image: url('${props => props.iconBg}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position:center;

    position: relative;

    outline: none;
    border: none;
    width: 50px;
    height: 75px;
    font-size: 4rem;

    display: flex;
    justify-content: center;
    align-items: flex-end;
`;

export default DownloadButton;