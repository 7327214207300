import React from 'react';
import styled from 'styled-components';

import logo from '../../assets/images/logo-slogan-01.png';
import Button from '../Button/Button.component'


export const SmallResolutionNoRender = () => {


    const title = 'Näyttökoko ei tuettu';
    const content = 'Pohjakuvapalvelun käyttö edellyttää vähintään 1024 kuvapisteen levyisen näytön.Palvelu ei ole käytettävissä mobiililaitteilla. Jos käytät tietokonetta, voit myös kokeilla zoomata näkymää ulos päin.Tyypillinen näppäinyhdistelmä tähän on ctrl + miinus (-)';
    const btnLink = "https://inlkv.fi/kohteet";

    return (
        <NoRenderWrapper minimumWidth={1023}>
            <div className="norender">
                <a href="/" >
                    <img width="240" src={logo} alt="Site logo" />
                </a >
                <h1 className="title">{title}</h1>
                <span className="desc">{content}</span>
            </div>
            <Button variant="primary" text="Takaisin sivustolle" href={btnLink} />
        </NoRenderWrapper>
    )
}

const NoRenderWrapper = styled.div`
    
    @media only screen and (min-width: 0px) and (max-width: ${props => props.minimumWidth}px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;
        width: 100vw;

        .norender {
            width: 68%;

            img {
                margin-bottom:20px;
            }

            @media only screen and (min-width: 415px) {
                text-align: center;
            }
            
            
            .title {
                font-family: "BentonModern";
                margin: 0;
                @media only screen and (max-width: 414px) {
                    font-size: 1.75rem;
                }
            }

            .desc {
                line-height: 1.55rem;
                @media only screen and (max-width: 768px) {
                    width: 100%;
                }
                @media only screen and (max-width: 414px) {
                }
                max-width: 55ch;
                display: block;
                margin: 30px auto;
            }

        }
        .btn {
            margin-top: 10px;
        }
    }

    @media only screen and (min-width: ${props => props.minimumWidth + 1}px) {
        display: none;
        visibility: hidden;
    }
`;

