import React, { useRef, useEffect, useState } from 'react';
import { Rect, Transformer } from 'react-konva';
import useImage from 'use-image';


const Furniture = ({
    FurnitureScaleY,
    FurnitureScaleX,
    position,
    uniqueid,
    setHudPosition,
    updateFurniturePosition,
    dimensions,
    isSelected,
    onSelect,
    bg,
    originalCategory,
    selectShape,
    hudOpen,
    resizeable,
    updateFurnitureResize,
    fillScale,
    updateFurnitureFillScale,
    rotation,
    updateFurnitureRotation,
    resetFurnitureCategory
}) => {

    const anchorPositions = ['top-left', 'top-center', 'top-right', 'bottom-left', 'bottom-center', 'bottom-right', 'middle-left', 'middle-right'];
    const elementAnchorPositions = ['top-center', 'bottom-center', 'middle-left', 'middle-right'];

    const shapeRef = useRef();
    const trRef = useRef();

    useEffect(() => {
        if (trRef.current && shapeRef.current) {
            if (isSelected) {
                trRef.current.getLayer().batchDraw();
                trRef.current.nodes([shapeRef.current]);
            }
        }
    }, [trRef.current])

    const [image] = useImage(bg, 'Anonymous');


    // Rect styles

    useEffect(() => {
        let timer1 = setTimeout(() => setStrokeColor(''), 1000);
        return () => {
            clearTimeout(timer1);
        };
    }, [])

    const [strokeColor, setStrokeColor] = useState((fillScale[0] === 0) ? 'royalblue' : null); // Render border if not re-render (resize)
    const [strokeWeight, setStrokeWeight] = useState(2);

    const [cornerRadius, setCornerRadius] = useState((fillScale[0] === 0) ? 5 : 0);

    const scaleX = (dimensions[0] / image?.width);
    const scaleY = (dimensions[1] / image?.height);
    const scale = Math.max(scaleX, scaleY);
    const [fillPatternScaleX, setFillPatternScaleX] = useState(fillScale[0]);
    const [fillPatternScaleY, setFillPatternScaleY] = useState(fillScale[1]);
    useEffect(() => {
        if (!isNaN(scaleX) && !isNaN(scaleY)) {
            if (fillScale[0] === 0) {
                setFillPatternScaleX(scaleX);
                setFillPatternScaleY(scaleY);
            } else {
                setFillPatternScaleX(fillScale[0]);
                setFillPatternScaleY(fillScale[1]);
            }
        }
    }, [scale])


    /**
     * 
     * @param {*} e
     * 
     * Handle resize and scaling
     *  
     */
    const resetSelect = e => {
        if (!isSelected) {
            resetFurnitureCategory(uniqueid);
        }
    }
    const handleChange = e => {

        if (resizeable) {
            const shape = e.target;

            const toShrinkX = scaleX / shape.scaleX();
            const toShrinkY = scaleY / shape.scaleY();

            let newX = toShrinkX;
            let newY = toShrinkY;

            if (newX < 0) {
                newX = newX * -1;
            }
            if (newY < 0) {
                newY = newY * -1;
            }

            setFillPatternScaleX(newX);
            setFillPatternScaleY(newY);

            updateFurnitureFillScale({
                uniqueid: uniqueid,
                x: newX,
                y: newY
            });
            updateFurnitureResize({
                uniqueid: uniqueid,
                scale: [shape.scaleX(), shape.scaleY()]
            });

            updateFurniturePosition(
                {
                    uniqueid: uniqueid,
                    position: e.target.getAbsolutePosition()
                }
            );
        }

        updateFurnitureRotation({
            uniqueid: uniqueid,
            rotation: e.target.rotation()
        });
    };

    const max_width = (originalCategory === 'Lattiamateriaalit') ? 20 : 10;

    return (
        <React.Fragment >
            {
                (dimensions && image) ?
                    <>
                        {/* Actual furniture */}
                        <Rect
                            draggable
                            ref={shapeRef}
                            rotation={rotation}
                            scaleY={FurnitureScaleY}
                            offsetY={FurnitureScaleY ? (dimensions[1] / 2) : (-dimensions[1] / 2)}
                            scaleX={FurnitureScaleX}
                            offsetX={FurnitureScaleX ? (dimensions[0] / 2) : (-dimensions[0] / 2)}

                            stroke={isSelected ? 'green' : strokeColor}
                            cornerRadius={(isSelected && !resizeable) ? 5 : cornerRadius}
                            strokeWidth={strokeWeight}
                            onClick={onSelect}
                            onTap={onSelect}
                            x={position.x}
                            y={position.y}

                            width={dimensions[0]}
                            height={dimensions[1]}
                            strokeScaleEnabled={false}
                            fillPatternImage={image}
                            fillPatternScaleX={fillPatternScaleX}
                            fillPatternScaleY={fillPatternScaleY}

                            onMouseEnter={e => {
                                // style stage container:
                                const container = e.target.getStage().container();
                                container.style.cursor = "pointer";
                                setStrokeColor('royalblue');
                                setStrokeWeight(1);
                                setCornerRadius(!resizeable ? 5 : 0);
                            }}
                            onMouseLeave={e => {
                                const container = e.target.getStage().container();
                                container.style.cursor = "default";
                                setStrokeColor('');
                                setCornerRadius(0);
                            }}
                            onDragStart={
                                e => {
                                    e.target.moveToTop();
                                    if (isSelected) {
                                        trRef.current.nodes([shapeRef.current]).moveToTop();
                                    }
                                    setHudPosition(e.target.getAbsolutePosition());
                                }
                            }
                            onDragMove={
                                e => {
                                    if (hudOpen && !isSelected) {
                                        selectShape(null);
                                    }
                                    setHudPosition(e.target.getAbsolutePosition());
                                }
                            }
                            onDragEnd={
                                e => {
                                    updateFurniturePosition(
                                        {
                                            uniqueid: uniqueid,
                                            position: e.target.getAbsolutePosition()
                                        }
                                    );
                                    resetSelect();
                                }
                            }
                            onTransformEnd={handleChange}

                        />
                    </>
                    : <></>
            }
            {
                isSelected && (
                    <Transformer
                        rotationSnaps={[45, 90, 135, 180, 225, 270, 315, 360]}
                        rotationSnapTolerance={5}
                        resizeEnabled={(bg.toLowerCase().includes('seina') || originalCategory === 'Lattiamateriaalit') && resizeable}
                        rotateEnabled={(originalCategory !== 'Lattiamateriaalit')}
                        anchorStroke={'#849884'}
                        anchorFill={'#849884'}
                        anchorSize={8}
                        anchorCornerRadius={30}
                        enabledAnchors={(originalCategory === 'Lattiamateriaalit') ? anchorPositions : elementAnchorPositions}
                        borderEnabled={false}
                        rotateAnchorOffset={50}
                        ref={trRef}
                        strokeScaleEnabled={false}
                        keepRatio={false}

                        boundBoxFunc={(oldBox, newBox) => {
                            if (newBox.width < max_width || newBox.height < 20) {
                                return oldBox;
                            }
                            return newBox;
                        }}
                    />
                )
            }


        </React.Fragment >

    );
};

export default Furniture;