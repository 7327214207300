import React, { useState } from "react";
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import dropdownImg from '../../../assets/images/Polygon.png';


const Dropdown = ({ floorCount, triggler }) => {
    const [check, setCheck] = useState(false);
    const activeFloor = useStoreState(state => state.floorPlans.active_floor);

    return (
        <>
            { Object.keys(floorCount).length > 1 ? 
          // Above code checks the number of floors that exist. 
                <DropdownWrap onClick={() => { setCheck(c => !c) }}>
                    <Button polygon={dropdownImg}><span>Kerros</span></Button>

                    <DropdownMenu isChecked={check}>

                        {
                            Object.keys(floorCount).map((key) => {
                            const floorData = floorCount[key].story.replace('Pohjakuva', "");
                            return (
                                <DropdownItem
                                    key={key}
                                    onClick={triggler}
                                    data-type={floorCount[key].story}
                                    isActive={floorCount[key].story == activeFloor}
                                >
                                    {floorData}
                                </DropdownItem>
                            )})
                        }
                    </DropdownMenu>
                </DropdownWrap>
            : null }
        </>
    )
}



const DropdownWrap = styled.div`
    margin-top: 15px;
    position: relative;
    height: 65px;
    width:225px;

    button.btn-primary {
        width:100%;
        height:100%;
        padding: 0 24px 0 40px;
        font-weight: bold;
        justify-content: space-between;
    }
`;

const Button = styled.button`
    width: 224px;
    height: 63px;
    background: #849884 0% 0% no-repeat padding-box;
    opacity: 1;
    outline: none;
    border:none;
    color: #fff;
    font: normal normal bold 20px/23px 'BentonSans';
    text-align:left;
    padding-left:42px;
    &:active {
        outline: none;
        border:none;
    }

    span {
        &::after {
            content: "";
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            display: block;
            border-top: 15px solid #fff;
            position: absolute;
            right: 24px;
            bottom: 26px;
        }
    }
`;

const DropdownMenu = styled.div`

    background: white;
    position: absolute;
    top: 75px;
    width:225px;


    will-change: transform;
    z-index: 1000;
    color: #212529;
    
    display: ${props => props.isChecked ? 'block' : 'none'};

`;

const DropdownItem = styled.button`
    display: block;
    padding: 14px 24px 14px 40px;
    font: normal normal normal 20px/23px 'BentonSans';
    color: #343434;
    background: ${props => props.isActive ? '#C1B2A2' : 'white'};
    outline:none;
    border:none;
    width:100%;
    min-height:50px;
    text-align:left;

    &:hover {
        background: #C1B2A2;
        cursor:pointer;
    }
`;

export default Dropdown;