import React from 'react';
import styled from 'styled-components';

const GenerationScreen = () => {
    return (
        <Screen>
            <h1>
                PDF tiedostoa generoidaan...
            </h1>
        </Screen>
    );
}

const Screen = styled.div`
    top:0;
    position:absolute;
    width: 100vw;
    height: 100vh;
    background:white;
    z-index:99999;
    display:grid;
    place-items:center;
    place-content:center;
    text-align:center;

    h1 {
        font-family: 'BentonModern';
        font-weight: 400;
    }
`;

export default GenerationScreen;