import React, { useState, useEffect } from "react";
import logo from '../../assets/images/logo-slogan-01.png';
import styled from 'styled-components';
import PropertyInfo from './PropertyInfo/PropertyInfo'
import Button from '../Button/Button.component'

import PlannerLogo from "../../assets/images/PlannerLogo-01.png"



export const Header = ({ propertyInfo, appVisibility }) => {

    const logo_link = propertyInfo?.property_mainsite_link;

    return (
        <>
            {
                appVisibility ?
                    <PageHeader>
                        <a href={logo_link}>
                            <img width="240" src={logo} alt="Site logo" />
                        </a>
                        <PropertyInfo info={propertyInfo} />
                        <Button variant="secondary" text="Vaihda kohdetta" href={"https://inlkv.fi/kohteet"} />
                        <img width="100" src={PlannerLogo} alt="Floorplanner logo" />
                    </PageHeader > : <></>

            }
        </>
    );
}

const PageHeader = styled.header`

    box-sizing: border-box;
    max-width: 1700px;
    margin:0 auto;
    
    display: flex;
    flex-direction:row;
    
    overflow: visible;
    z-index: 9999;
    position: relative;
    
    justify-content: space-between;
    align-items: center;
    align-content: center;
    justify-items: center;

    height:160px;
    margin-top: 30px;

    .btn {
        span {
            font-weight: bold;
            font-size: 18px;
        }
    }
    
    @media only screen and (max-width: 1750px) {
        height:160px;
        margin-left: 20px;
        margin-right: 20px;
    }

    @media only screen and (max-width: 1023px) {
        display: none;
    }

`;