import React from 'react';
import styled from 'styled-components';


const PropertyInfo = ({ info }) => {

    const title = info?.property_title;
    const postarea = info?.property_postarea;
    const quarteroftown = info?.property_quarteroftown;
    const structure = info?.property_structure;

    // Finds every occurrence of "+" and adds spaces before and after.
    const dataModifier = (param) => {
        if (param) return param.split("+").join(" + ");
    }

    // Create our number formatter.
    const formatter = new Intl.NumberFormat('fi-FI', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    const price = formatter.format(info.property_price);

    return (
        <Info>
            {title ?
                <h1 className="property-title">
                    {`${postarea}, ${quarteroftown}, ${title}`}
                </h1> : ''}
            <p className="property-floorplan-details">{dataModifier(structure)}</p>
            <h2 className="property-price"><span className="vhp">VHP </span>{!price.includes('epäluku') ? price : ''}</h2>
        </Info>
    )
}

const Info = styled.section`
    padding: 0 20px;
    max-width:460px;

    .vhp {
        font-size: 14px;
    }
    

    * {
        margin: 0;
    }

    .property-title {
        text-align: left;
        margin: 0;
        letter-spacing: 0.5px;

        font: normal normal normal 30px/33px 'BentonModern';
    }

    .property-floorplan-details {
        margin-top: 15px;
        line-height: 3rem;
        text-transform: capitalize;
        font: normal normal normal 20px/23px 'BentonSans';
        text-transform: uppercase;
    }

    .property-price {
        20px 0 10px 0;
        font: normal normal bold 30px/35px 'BentonSans';
        margin: 20px 0 0 0;
    }
`;

export default PropertyInfo;
